import { useEffect, useRef } from "react";
import { createFunnelEvent } from "../config/Analytics";

export const useInactivityTracking = ({
  step,
  experiment,
  path,
  leadType = null, // Optional since landing page doesn't have leadType
  isLoaded = true, // Default to true for backwards compatibility
}) => {
  // Add ref to track previous step
  const prevStepRef = useRef(step);

  useEffect(() => {
    if (!isLoaded || !step) return;

    // Log step changes
    if (prevStepRef.current !== step) {
      console.debug(
        `Time tracking: Step changed from ${prevStepRef.current} to ${step}. Resetting timers.`
      );
      prevStepRef.current = step;
    }

    // Reset all tracking variables
    const timeouts = [];
    let hasTracked45 = false;
    let hasTracked90 = false;
    let startTime = Date.now();
    let isTabVisible = !document.hidden; // Initialize based on current visibility
    let accumulatedTime = 0;

    const trackTimeOnPage = (seconds) => {
      // Only track if the tab is visible

      const details = {
        time_on_page: seconds,
        page: window.location.href,
        path,
        tab_visible: isTabVisible,
        current_step: step,
      };

      // Only add leadType if it exists
      if (leadType) {
        details.leadType = leadType;
      }

      console.debug(`Time tracking: Tracked ${seconds}s on step ${step}`);

      createFunnelEvent({
        step,
        expid: experiment,
        details,
        eventType: "time_on_page",
      });
    };

    // Handle tab visibility changes
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Tab became hidden, store accumulated time
        isTabVisible = false;
        const timeToAdd = Date.now() - startTime;
        accumulatedTime += timeToAdd;
        console.debug(
          `Time tracking: Tab hidden on step ${step}. Adding ${timeToAdd}ms. Total accumulated: ${accumulatedTime}ms`
        );
        // Clear existing timeouts when tab becomes hidden
        timeouts.forEach((timeout) => clearTimeout(timeout));
      } else {
        // Tab became visible, reset start time
        isTabVisible = true;
        startTime = Date.now();
        console.debug(
          `Time tracking: Tab visible on step ${step}. Accumulated time: ${accumulatedTime}ms`
        );
        // Set new timeouts based on remaining time needed
        setupTimeouts();
      }
    };

    const setupTimeouts = () => {
      // Clear any existing timeouts
      timeouts.forEach((timeout) => clearTimeout(timeout));
      timeouts.length = 0;

      const currentTime = Date.now();
      const totalTimeSpent = accumulatedTime + (currentTime - startTime);

      console.debug(
        `Time tracking: Setting up timeouts for step ${step}. Total time spent: ${totalTimeSpent}ms`
      );

      // Setup 45 second timeout if not yet tracked
      if (!hasTracked45 && totalTimeSpent < 45000) {
        const timeUntil45 = 45000 - totalTimeSpent;
        timeouts.push(
          setTimeout(() => {
            if (isTabVisible && !hasTracked45) {
              trackTimeOnPage(45);
              hasTracked45 = true;
            }
          }, timeUntil45)
        );
        console.debug(
          `Time tracking: 45s timer set for ${timeUntil45}ms from now`
        );
      }

      // Setup 90 second timeout if not yet tracked
      if (!hasTracked90 && totalTimeSpent < 90000) {
        const timeUntil90 = 90000 - totalTimeSpent;
        timeouts.push(
          setTimeout(() => {
            if (isTabVisible && !hasTracked90) {
              trackTimeOnPage(90);
              hasTracked90 = true;
            }
          }, timeUntil90)
        );
        console.debug(
          `Time tracking: 90s timer set for ${timeUntil90}ms from now`
        );
      }
    };

    // Initial setup
    document.addEventListener("visibilitychange", handleVisibilityChange);
    setupTimeouts();

    return () => {
      console.debug(`Time tracking: Cleaning up timers for step ${step}`);
      timeouts.forEach((timeout) => clearTimeout(timeout));
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [step, experiment, path, leadType, isLoaded]);
};
