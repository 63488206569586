import { useExperiment } from "../../../../contexts/ExperimentContext";
import styles from "./PanelHeading.module.scss";

export default function PanelHeading({
  children,
  center,
  hasDescription,
  step,
  className,
}) {
  const { experiment } = useExperiment();

  const classNames = `${step ? styles["--new"] : ""} ${
    step ? styles["--non-landing-heading"] : ""
  } ${styles["PanelHeading"]} ${center ? styles["--center"] : ""} ${
    hasDescription ? styles["--has-description"] : ""
  } ${styles["--phone-redux"]} ${className ?? ""}`.trim();
  return <h2 className={classNames}>{children}</h2>;
}
