import styles from "./Page.module.scss";

export default function Page({ children, className }) {
  return (
    <div className={styles["Page"]}>
      <div
        className={styles["Page__container"] + className ? ` ${className}` : ""}
      >
        {children}
      </div>
    </div>
  );
}
