import { StyleSheet, css } from "aphrodite";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { isMobile as isMobileDetect } from "react-device-detect";
import styles from "./ProgressBar.module.scss";

import { useRouter } from "next/router";
import { useCallback } from "react";
import { isNextSubmit, prevPage } from "../../../../config/PageRouting";
import { useExperiment } from "../../../../contexts/ExperimentContext";

/**
 * ProgressBar
 *
 * Progress bar component that we can animate.
 *
 * @param {*} props
 * @returns
 */
const ProgressBar = (props) => {
  const { maxSteps, currentStep } = props;
  const { experiment } = useExperiment();

  const [statePercentage, setStatePercentage] = useState(8);
  const [displayPercentage, setDisplayPercentage] = useState(0);

  const [isMobile, setIsMobile] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setIsMobile(isMobileDetect);
  }, []);

  const isSubmit = isNextSubmit(experiment);

  const floor = useCallback(() => {
    if (isSubmit) {
      return 1;
    }

    if (currentStep / maxSteps < 0.19) {
      return 0.19;
    } else {
      return currentStep / maxSteps;
    }
  }, [currentStep, isSubmit, maxSteps]);

  useEffect(() => {
    setStatePercentage(floor() * 100);
  }, [floor]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayPercentage(statePercentage);
    }, 150);

    return () => clearTimeout(timer);
  }, [statePercentage]);

  const className = `${styles.ProgressBar__inner} ${
    currentStep >= maxSteps ? styles["--full"] : ""
  }`.trim();

  const shouldShowCallouts =
    currentStep > 2 && (currentStep !== 0 ? true : currentStep === maxSteps);

  const goBack = () => {
    prevPage(experiment);
  };

  return (
    <div className={css(aphStyles.container)}>
      {currentStep > 0 && (
        <i
          className={`fa-solid fa-chevron-left ${css(aphStyles.backIcon)}`}
          onClick={goBack}
        />
      )}
      <div className={[styles.ProgressBar, styles.ProgressBarNew].join(" ")}>
        <div
          className={className}
          style={{ maxWidth: `${statePercentage}%` }}
        />
        {shouldShowCallouts ? (
          <div
            style={{ left: `${statePercentage}%` }}
            className={`${styles.callout} ${css(
              aphStyles.callout,
              (currentStep === maxSteps || isSubmit) && aphStyles.calloutLast
            )}`}
          >
            {currentStep === maxSteps || isSubmit
              ? "Last Step!"
              : `Step ${currentStep} / ${maxSteps}`}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const aphStyles = StyleSheet.create({
  container: {
    position: "relative",
  },
  callout: {
    textAlign: "center",
  },
  backIcon: {
    position: "absolute",
    left: -32,
    top: -6.5,
    cursor: "pointer",
    padding: 4,
    color: "#565D5A",
    // marginBottom: 10,
  },
  calloutLast: {
    width: 80,
    right: 0,
    left: "unset",
    transform: "unset",
  },
});

ProgressBar.propTypes = {
  maxSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
};

ProgressBar.defaultProps = {
  maxSteps: 100,
  currentStep: 0,
};

export default ProgressBar;
